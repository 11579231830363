<template>
  <nav
    class="navbar--spacer"
    :class="[
      `navbar__bg--${color || 'default'}`,
      {
        'navbar--elevated': elevated,
        'navbar--fixed fixed-fullscreen-element-pad': fixed,
        'navbar--border': border,
        'navbar--absolute': absolute,
        'navbar--not-sticky': notSticky,
        'navbar--dense': dense || !!hasMaxHeight,
        'navbar--custom-height': !!hasMaxHeight,
      },
    ]"
    :style="[
      hasZIndex && { '--z-index': zIndex },
      hasMaxHeight && { '--nav-height': toUnit(maxHeight) },
      hasIconsWidth && { '--icons-width': toUnit(iconsWidth) },
      stickyTopDistance && { '--sticky-height': -stickyTopDistance + 'px' },
    ]"
  >
    <slot name="pre" />
    <div
      ref="wrapper"
      :class="[
        'navbar__navigation',
        prefixClass('navigation'),
        typeof container === 'boolean' && container ? 'px-s container' : container,
        { 'navbar--flip': flip },
      ]"
    >
      <!-- Back action -->
      <template v-if="!hasBackSlot">
        <UiButton
          v-if="!hideBack"
          variant="link"
          size="flat"
          :to="backTo"
          :class="[
            'navbar__icon navbar__icon--back',
            prefixClass(['icon', 'icon--back']),
            {
              'navbar__icon--with-slot': hasBackTextSlot,
              [prefixClass('icon--with-slot')]: hasBackTextSlot,
            },
          ]"
          :title="$t('common.back')"
          :aria-label="$t('common.back')"
          tabindex="0"
          @click="$emit('onBack')"
          @keydown.enter.prevent="$emit('onBack')"
        >
          <UiNuxtIcon name="chevron" aria-hidden="true" fill="transparent" :stroke="iconsColor" filled />
          <slot name="back-text" />
        </UiButton>
        <div v-else :class="['navbar__icon navbar__icon--hidden', prefixClass(['icon', 'icon--hidden'])]" />
      </template>

      <!-- Back slot -->
      <div
        v-else
        :class="['navbar__icon navbar__icon--slot nav-slot--back', prefixClass(['icon', 'icon--slot'])]"
      >
        <slot name="back" />
      </div>

      <!-- Middle section -->
      <div :class="['navbar__navigation--middle', prefixClass('navigation--middle')]">
        <slot />
      </div>

      <!-- Close action -->
      <template v-if="!hasCloseSlot">
        <UiButton
          v-if="!hideClose"
          variant="link"
          size="flat"
          :to="closeTo"
          :class="[
            'navbar__icon navbar__icon--close',
            prefixClass(['icon', 'icon--close']),
            {
              'navbar__icon--with-slot': hasBackTextSlot,
              [prefixClass('icon--with-slot')]: hasBackTextSlot,
            },
          ]"
          :title="$t('common.close')"
          :aria-label="$t('common.close')"
          tabindex="0"
          @click="$emit('onClose')"
          @keydown.enter.prevent="$emit('onClose')"
        >
          <slot name="close-text" />
          <slot name="close-icon">
            <UiNuxtIcon
              class="nav-svg"
              :size="20"
              name="close-icon"
              :stroke="iconsColor"
              aria-hidden="true"
            />
          </slot>
        </UiButton>
        <div v-else :class="['navbar__icon navbar__icon--hidden', prefixClass(['icon', 'icon--hidden'])]" />
      </template>

      <!-- Close slot -->
      <div
        v-else
        :class="['navbar__icon navbar__icon--slot nav-slot--close', prefixClass(['icon', 'icon--slot'])]"
      >
        <slot name="close" />
      </div>
    </div>

    <!-- Extension slot -->
    <!-- <UiTransition name="scrollY" appear> -->
    <div v-if="hasExtensionSlot" :class="['navbar__extension', prefixClass('extension')]">
      <slot name="extension" />
    </div>
    <!-- </UiTransition> -->
  </nav>
</template>

<script>
export default {
  name: "Navbar",

  emits: ["onBack", "onClose"],

  props: {
    /* Default as #FBFAF8, available colors: white, transparent */
    color: {
      type: String,
      default: "default",
    },

    /* Remove paddings from buttons */
    dense: Boolean,

    /* Determains max height for the navbar, Also applies dense prop by default */
    maxHeight: [Number, String],

    /* Determains max height for the navbar, Also applies dense prop by default */
    iconsWidth: [Number, String],

    /* Changes z-index, default is 9 */
    zIndex: [Number, String],

    /* Applies a border to the navbar container */
    border: Boolean,

    /* Applies box shadow to the navbar container */
    elevated: Boolean,

    /* Hides Back button */
    hideBack: Boolean,

    /* Hides Close button */
    hideClose: Boolean,

    /* Sets the navbar to position absolute (default: sticky) */
    absolute: Boolean,

    /* Sets the navbar to position fixed (default: sticky) */
    fixed: Boolean,

    /* Changes the flex-direction to row-reverse (default: sticky) */
    flip: Boolean,

    /* Remove 'poisition' property from navbar container */
    notSticky: Boolean,

    /* Applies stickiness to sticky extension on scroll */
    stickyExtension: Boolean,

    /* Applies the container class to navbar naviagtion / act as a container class */
    container: { type: [Boolean, String], default: null },

    /* Applies more classes with the given prefix, useful when using multiple navbars */
    classesPrefix: String,

    /* Changes icons color, default to theme.$color-dark */
    iconsColor: {
      type: String,
      default: "#1F1F1F",
    },

    /* Native router-link behavior for back action */
    backTo: [String, Object],

    /* Native router-link behavior for close action */
    closeTo: [String, Object],
  },

  data: () => ({
    stickyTopDistance: 0,
  }),

  computed: {
    hasZIndex() {
      return this.zIndex !== null || this.zIndex === 0
    },
    hasMaxHeight() {
      return !!this.maxHeight && (this.maxHeight !== null || this.maxHeight === 0)
    },
    hasIconsWidth() {
      return !!this.iconsWidth && (this.iconsWidth !== null || this.iconsWidth === 0)
    },
    hasBackSlot() {
      return !!this.$slots.back
    },
    hasBackTextSlot() {
      return !!this.$slots?.["back-text"]
    },
    hasCloseSlot() {
      return !!this.$slots.close
    },
    hasCloseTextSlot() {
      return !!this.$slots?.["close-text"]
    },
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    hasExtensionSlot() {
      return !!this.$slots.extension
    },
  },

  updated() {
    if (this.stickyExtension) this.setSticky()
  },

  methods: {
    prefixClass(c) {
      const mapPrefix = (x) => this.classesPrefix + "__" + x

      if (!this.classesPrefix) return
      else if (Array.isArray(c)) return c.map(mapPrefix).join(" ")

      return mapPrefix(c)
    },
    setSticky() {
      this.stickyTopDistance = this.$refs.wrapper.offsetHeight
    },
    toUnit(v) {
      return /^\d+$/.test(v) ? v + "px" : v
    },
  },
}
</script>
<style lang="scss">

// Navbar colors
.navbar__bg {
  &--green {
    background-color: theme.$color-main-green4 !important;
    --secondary-bg-color: #{lighten(theme.$color-main-green4, 10%)} !important;
    --nav-text-color: #{theme.$light-cyan} !important;
  }

  &--lightyellow {
    background-color: theme.$color-light-yellow2 !important;
    --secondary-bg-color: #{darken(theme.$color-light-yellow2, 3%)} !important;
  }

  &--pink {
    background-color: theme.$color-light-pink !important;
    --secondary-bg-color: #{lighten(theme.$color-light-pink, 10%)} !important;
  }

  &--light-brown {
    background-color: theme.$color-light-yellow !important;
    --secondary-bg-color: #{lighten(theme.$color-light-yellow, 10%)} !important;
    --nav-text-color: #{theme.$color-dark} !important;
  }

  &--white {
    background-color: theme.$white !important;
    --secondary-bg-color: #{lighten(theme.$white, 10%)} !important;
    --nav-text-color: #{theme.$color-dark} !important;
  }

  &--light-pink {
    background-color: theme.$color-light-pink !important;
    --secondary-bg-color: #{lighten(theme.$color-light-pink, 10%)} !important;
    --nav-text-color: #{theme.$color-dark} !important;
  }
  &--light-blue {
    background-color: #dfeceb !important;
    --secondary-bg-color: #{lighten(#dfeceb, 10%)} !important;
    --nav-text-color: #{theme.$color-dark} !important;
  }

  &--transparent {
    background-color: transparent !important;
    --nav-text-color: #{theme.$color-dark} !important;
  }

  &--default {
    background-color: rgba(255, 255, 255, 0.9) !important;
    --secondary-bg-color: #{lighten(theme.$white, 10%)} !important;
    --nav-text-color: #{theme.$color-dark} !important;
    backdrop-filter: blur(10px);
  }
}

// Navbar options
.navbar {
  &--absolute {
    position: absolute !important;
    background-color: transparent !important;
    pointer-events: none;

    .navbar__icon {
      pointer-events: all;
    }
  }

  &--flip {
    flex-direction: row-reverse;
  }

  &--fixed {
    position: fixed !important;
  }

  &--custom-height {
    min-height: unset;
  }

  &--not-sticky {
    position: initial !important;
  }

  &--border {
    border-bottom: solid 1px theme.$divider-color;
  }

  &--elevated {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  }

  &--custom-z-index {
    box-shadow: 0 3px 14px 5px rgba(176, 176, 176, 0.11);
  }
}

:root {
  --nav-text-color: #{theme.$color-dark};
}

.navbar {
  &--spacer {
    --sticky-height: 0;
    --nav-height: 56px;
    --icons-width: 50px;
    --z-index: 9;
    width: 100%;
    border-radius: inherit;
    min-height: var(--nav-height);
    position: sticky;
    top: var(--sticky-height);
    z-index: var(--z-index);
    background-color: theme.$color-light-bg;
    color: var(--nav-text-color);
    transition:
      box-shadow 0.3s ease,
      background-color 0.2s ease-out,
      color 0.2s ease-out;
  }

  &__navigation {
    height: 100%;
    width: 100%;
    min-height: var(--nav-height);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--middle {
      flex: 1;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  &__icon {
    min-width: var(--icons-width);
    max-width: var(--icons-width);
    min-height: var(--nav-height);
    transition: 166ms ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;
    &--close {
      // justify-content: flex-end;
    }
    &--back {
      // justify-content: flex-start;
      .nav-svg {
        width: 30px;
      }
    }

    &--with-slot {
      display: flex;
      align-items: center;
      max-width: unset !important;

      &.navbar__icon--back .nav-svg {
        margin-inline-end: 1rem;
      }

      &.navbar__icon--close .nav-svg {
        margin-inline-start: 1rem;
      }
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }

    &--slot {
      display: flex;
      max-width: none;
      align-items: center;
      justify-content: center;
    }

    .nav-svg {
      max-width: 1.5rem;
      max-height: 1.5rem;
      transition: 166ms ease-in-out;

      * {
        transition: 66ms ease-out;
      }

      &[stroke] * {
        fill: inherit !important;
        stroke: inherit !important;
      }
    }

    &:not(&--slot):hover {
      cursor: pointer;
      font-weight: 500;

      .nav-svg * {
        stroke-width: 3px;
      }
    }

    &:active {
      &.navbar__icon--close .nav-svg {
        transform: rotate(0.25turn);
      }

      &.navbar__icon--back .nav-svg {
        transform: translateX(0.5rem);
      }
    }

    .app-ltr & {
      &.navbar__icon--back {
        .nav-svg {
          transform: scaleX(-1);
        }

        &:active {
          .nav-svg {
            transform: scaleX(-1) translateX(0.5rem);
          }
        }
      }
    }
  }

  @include theme.media(">md") {
    &--spacer {
      --icons-width: 100px;
    }
    &__icon {
      min-width: var(--icons-width);
      max-width: var(--icons-width);
      padding: 20px 1rem;

      .navbar--dense & {
        padding: 0;
      }
    }
  }
}
</style>
